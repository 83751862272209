/**
 * Code for filling operations used in dsfilling.php
 * Created by andrew.stalker on 06/12/2018.
 */

/**
 * @typedef {Object} fillingCheckResponse
 * @property {String} fillingLine
 * @property {String} status
 * @property {String} fillingType
 * @property {Number} caskType
 * @property {String} woodCode
 * @property {Number} spiritType
 * @property {String} blendCode
 * @property {Number} firstCask
 */

function setFillingLines(data) {
  "use strict";
	localStorage.setObject("data/fillingLines", data);
}

function getFillingLines() {
	"use strict";
	return localStorage.getObject("data/fillingLines");
}

function getFillingLineById(id) {
	"use strict";
	var desiredFillingLines = getFillingLines().filter(function(element) {
		return element.id === id;
	});
	return (desiredFillingLines.length > 0)? desiredFillingLines[0]: null;
}

/**
 * @description Checks the filling line with the server to see that there is an active filling. Receives a response of fillingCheckResponse
 * @param {String} fillingLine - The filling line to be checked
 * @param callback - The function to run once done. Passes (Boolean, Object|Number|Null) depending on response
 */
function checkFillingLine(fillingLine, callback) {
  "use strict";

	var scanner = getScannerId(),
		  company = getScannerCompany(),
		  location = getScannerLocationCode();

	$.ajax({
		url: getRequestPath("fillingLine/status?company=" + company + "&scanner=" + scanner + "&location=" + location + "&line=" + fillingLine),
		type: "GET",
		contentType: "application/JSON",
		timeout: requestTimeout,
		xhrFields: {withCredentials: true},
		success: function (data,status,xhr) {
			switch (xhr.status) {
				case 200:
					//Good - check response
					callback(true, data);
					break;
				case 404:
					//Not found - was successful though.
					callback(true, 404);
					break;
				case 401:
					if (canReattemptAfterLogin()) {
						renewServerSession(function (valid) {
							if (valid) {
								//call self again.
								checkFillingLine(fillingLine, callback);
							} else {
								callback(false, 401); // renew session was unsuccessful
							}
						});
					} else {
						callback(false, 401); // Already tried to renew server session
					}
					break;
				default:
					callback(false, xhr.status);//bad - the request failed for some reason
					break;
			}
		},
		error: function () {
			callback(false, null); //Networking error - silent
		}
	});
}

/**
 * @description Checks a caskID with the server to see if it can be filled in the current filling.
 * @param caskId
 * @param callback
 */
function checkCaskForFilling(caskId, callback) {
	"use strict";
	var scanner = getScannerId(),
		company = getScannerCompany();
	showAjaxLoading();

	$.ajax({
		url: getRequestPath("cask/"+caskId+"/check?coyno=" + company + "&scanner=" + scanner),
		type: "GET",
		contentType: "application/JSON",
		timeout: requestTimeout,
		xhrFields: {withCredentials: true},
		success: function (data,status,xhr) {
			hideAjaxLoading();
			switch (xhr.status) {
				case 200:
					//Good - check response
					callback(true, data);
					break;
				case 404:
					//Not found - the request was successful though
					callback(false, 404);
					break;
				case 401:
					if (canReattemptAfterLogin()) {
						renewServerSession(function (valid) {
							if (valid) {
								//call self again.
								checkCaskForFilling(caskId, callback);
							} else {
								callback(false, 401); // renew session was unsuccessful
							}
						});
					} else {
						callback(false, 401); // Already tried to renew server session
					}
					break;
				default:
					callback(false, xhr.status);//bad - the request failed for some reason
					break;
			}
		},
		error: function (xhr) {
			hideAjaxLoading();
			switch (xhr.status) {
				case 404:
					//Not found - the request was successful though
					callback(false, 404);
					break;
				case 401:
					if (canReattemptAfterLogin()) {
						renewServerSession(function (valid) {
							if (valid) {
								//call self again.
								checkCaskForFilling(caskId, callback);
							} else {
								callback(false, 401); // renew session was unsuccessful
							}
						});
					} else {
						callback(false, 401); // Already tried to renew server session
					}
					break;
				default:
					callback(false, xhr.status);//bad - the request failed for some reason
					break;
			}
		}
	});
}